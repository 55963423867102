import React from "react"
import { graphql } from "gatsby"
import { Fade } from "react-awesome-reveal"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import PageTitleSimple from "../../components/PageTitleSimple"

const isBrowser = typeof window !== "undefined"

function SimpleTextPage({ location, data }) {

  if (isBrowser) {

    if (!data) return null
    
    const url = location.href ? location.href : '';
    const { type, uid } = data.allPrismicSimpleText.edges[0].node
    const document = data.allPrismicSimpleText.edges[0].node.data
    const { title, copy } = document

    return (
      <Layout pageType={type} uid={uid}>
        <Seo title={title.text} url={url}/>
        <Fade triggerOnce>
          <PageTitleSimple title={title.text} />
          <section className="py-6 border-t md:py-12 border-grey-10">
            <div className="mx-auto lg:max-w-screen-lg">
              <div className="container-fluid">
                <div className="simple-text">
                  <div dangerouslySetInnerHTML={{ __html: copy.html }} />
                </div>
              </div>
            </div>
          </section>
        </Fade>
      </Layout>
    )
  }

  return <div> </div>
}

SimpleTextPage.propTypes = {}

export const query = graphql`
  query SimpleTextPageQuery($uid: String) {
    allPrismicSimpleText(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          uid
          type
          data {
            title {
              text
            }
            copy {
              html
            }
          }
        }
      }
    }
  }
`

export default SimpleTextPage
